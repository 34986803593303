@import '~antd/dist/antd.css';

html, body, #root, #root>div {
    height: 100vh;
}

.page-container {
    display: flex;
    background-color: #FFFFFF;
}

.home .page-container {
    background: #fafafa;
}

.page-container-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1356px; /* 1100px + 256px (max sidebar width) */
}

.page-content {
    min-height: 100vh;
    max-width: 100vw;
    border: 1px solid #e8e8e8;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 1px;
    border-right-width: 1px;
}

.page-content .ant-page-header {
    border: 1px solid #e8e8e8;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
}

.page-content .ant-layout {
    background: #fafafa;
}

.page-content .ant-layout-footer {
    background: none;
}

.home .page-content {
    border: 0;
}

.content {
    padding: 4em;
    width: 100%;
    height: 100%;
}

.menu-bar {
    height: 100vh;
    position: relative;
}

.menu-bar .ant-menu-item .anticon {
    font-size: inherit;
}

.full-logo {
    display: flex;
}

.hmt-form .ant-form-item {
    margin-bottom: 0.75em;
}

.mobile-menu-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 18px;
}

.mobile-menu-collapse .ant-collapse-content-box {
    padding: 0;
}

.home-loading-container {
    display: flex;
}

.home-loading-spin {
    margin: auto;
}

.home-loading-spin img {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 2em;
    height: 2em;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.home-container {
    display: inline-block;
    text-align: justify;
    max-width: 720px;
}

.black-button {
    color: #FFFFFF;
    background-color: #000000;
    border-color: #FFFFFF;
    margin-bottom: 0.5em;
}

.black-button:hover {
    color: #000000;
    background-color: #FFFFFF;
    border-color: #000000;
}

.home-action-button span {
    display: block;
    padding: 0.5em;
}

.trackless-radio {
    display: flex;
    text-align: center;
}

.trackless-radio .ant-radio-button-wrapper {
    width: 50%;
    height: auto;
    padding: 15px;
    display: flex;
}

.ant-radio-button-wrapper span {
    margin-top: auto;
    margin-bottom: auto;
    line-height: 2;
}

.scrollable-container {
    position: relative;
    overflow: hidden;
    padding: 0 0.25em;
}

.scrollable-container .shadow {
    position: absolute;
    height: 16px;
    width: 100%;
}

.scrollable-container .shadow-top {
    top: -16px;
    box-shadow: 0 2px 16px rgba(200, 200, 200, 0.8);
}

.scrollable-container .shadow-bottom {
    bottom: -16px;
    box-shadow: 0 -2px 16px rgba(200, 200, 200, 0.8);
}

.replies-container {
    overflow-y: scroll;
    max-height: 560px;
}

.replies-container .ant-comment-inner {
    padding: 0.25em 0;
}

.request-button {
    line-height: 0;
}

/* Desktop styles */
@media only screen
  and (min-width: 768px) {
    .menu-bar .ant-menu-item {
        font-weight: bold;
        font-size: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

/* Mobile styles */
@media only screen
  and (max-width: 768px) {
    .page-container {
        min-width: 100vw;
    }

    .content {
        padding: 2em;
    }

    .home-container {
        display: block;
    }

    .menu-bar {
        height: auto;
    }

    /* styles to make modals fill screen on mobile devices */
    .responsive-modal {
        max-width: 100vw;
        min-width: 100vw;
        min-height: 100vh;
        margin: 0;
        top: 0;
        padding: 0;
    }

    .responsive-modal .ant-modal-content {
        border-radius: 0;
        min-height: 100vh;
    }

    .replies-container {
        max-height: 75vh;
    }
}
